<template>
  <ModalFormGeneric :id="id" :title="$t('tra.cre.t')" :btnText="$t('btn.c')" btnIcon="acc" :btnDisabled="!(valAmount && valRef)"
      @submit="onSubmit" @reset="onReset">
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('traM.fam')" :label-for="id+'FormAm'" class="text-left">
          <b-form-input :id="id+'FormAm'" type="number" number :min="defMin" :max="defMax" :step="0.01" debounce="500"
              v-model="amount" :state="valAmount" required />
          <b-form-invalid-feedback :state="valAmount">
            {{ $t('tra.cre.w1') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="*" sm="6">
        <b-form-group :label="$t('traM.fcu')" :label-for="id+'FormCur'" class="text-left">
          <b-form-select :id="id+'FormCur'" v-model="f.currency" :options="cList" text-field="t" value-field="v" :state="valCurr" disabled>
            <template #first>
              <option :value="null" disabled>- {{ $t('dial.selopt') }} -</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="flex-column flex-sm-row">
      <b-col cols="12">
        <b-form-group :label="$t('traM.fre')" :label-for="id+'FormRef'" class="text-left">
          <b-form-input :id="id+'FormRef'" type="text" debounce="500"
              v-model="f.ref" :state="valRef" required />
          <b-form-invalid-feedback :state="valRef">
            {{ $t('tra.cre.w2') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-form-row>
  </ModalFormGeneric>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import curList from '@/components/data/Currencies';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      f: {
        senderUuid4: null,
        receiverUuid4: null,
        reservationUuid4: null,
        amount: null,
        currency: null,
        ref: null,
      },
      defMin: 5.00,
      defMax: 250.00,
    };
  },
  computed: {
    amount: {
      get: function() {
        if (this.f.amount === null) return null;
        return this.f.amount / 100;
      },
      set: function(v) {
        if (v === null) {
          this.f.amount = null;
        } else {
          this.f.amount = Math.round(v * 100);
        }
      },
    },
    valAmount() {
      if (this.f.amount === null) return null;
      let val = parseInt(this.f.amount);
      if (isNaN(val)) return false;
      return val >= this.defMin*100 && val <= this.defMax*100;
    },
    valRef() {
      if (this.f.ref === null) return null;
      return this.f.ref.length > 1 && this.f.ref.length <= 32;
    },
    valCurr() {
      if (this.f.currency == null) return null;
      return this.f.currency === 'EUR';
    },
    cList() {
      return curList;
    },
    uuid() {
      return this.$store.state.oLogin.uuid;
    },
  },
  mounted() {
    this.dataReset();
    this.f.senderUuid4 = this.senderUuid;
    this.f.receiverUuid4 = this.uuid;
    this.f.reservationUuid4 = this.reservationUuid;
    this.f.currency = 'EUR';
    this.$bvModal.show(this.id);
  },
  methods: {
    onSubmit() {
      if (this.f.senderUuid4 != null && this.valAmount) {
        let self = this;
        REST.post('/transactions', this.f)
            .then(async function(resp) {
                // show for user
                self.$store.commit('showSuccess',resp.d.message);
            }).catch(e => {
                this.$store.commit('showWarn',e.message)
            }).finally(() => {
                this.$bvModal.hide(this.id);
                this.$emit('submit');
            });
      } else {
        this.$bvModal.hide(this.id);
        this.$emit('submit');
      }
    },
    onReset(ev) {
      this.dataReset();
      this.$bvModal.hide(this.id);
      this.$emit('reset', ev);
    },
    getMoney(a) {
      if (a == null || this.f.currency == null) return '';
      return this.$n(a/100, { key: 'c', currency: this.f.currency });
    },
    dataReset() {
      this.f.senderUuid4 = null;
      this.f.receiverUuid4 = null;
      this.f.reservationUuid4 = null;
      this.f.amount = null;
      this.f.currency = null;
      this.f.ref = null;
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    senderUuid: {
      type: String,
      required: true,
    },
    reservationUuid: {
      type: String,
      required: true,
    },
  },
  components: {
    ModalFormGeneric,
  },
}
</script>
